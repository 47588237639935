// see https://sdl-portal.githubapp.com/guidelines/client-side-html-rendering
export const dompurifyConfig = {
  FORBID_TAGS: ['style'],
  ALLOW_DATA_ATTR: false,
  ALLOWED_TAGS: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'h7',
    'h8',
    'br',
    'b',
    'i',
    'strong',
    'em',
    'a',
    'pre',
    'code',
    'img',
    'tt',
    'div',
    'span',
    'ins',
    'del',
    'sup',
    'sub',
    'p',
    'ol',
    'ul',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'blockquote',
    'dl',
    'dt',
    'dd',
    'kbd',
    'q',
    'samp',
    'var',
    'hr',
    'ruby',
    'rt',
    'rp',
    'li',
    'tr',
    'td',
    'th',
    's',
    'strike',
    'summary',
    'details',
    'picture',
    'source',
  ],
}
