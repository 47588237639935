export const LABELS = {
  archivedDescription: 'Archived in project:',
  archivedError: 'Issue could not be restored in project',
  archivedSuccess: 'Issue restored in project',
  emptySections: {
    labels: 'No labels',
    assignees: (viewerCanAssign: boolean) => (viewerCanAssign ? 'No one - ' : 'No one assigned'),
    selfAssign: 'Assign yourself',
    milestones: 'No milestone',
    projects: 'No projects',
    status: 'No status',
    types: 'No type',
    noValue: (fieldName: string) => `No ${fieldName.toLowerCase()}`,
    iterationPlaceholder: 'Choose an iteration',
    datePlaceholder: 'Choose a date',
    numberPlaceholder: 'Enter number…',
    iterationFilterPlaceholder: 'Choose an option',
    singleSelectFilterPlaceholder: 'Choose an option',
  },
  sectionTitles: {
    assignees: 'Assignees',
    labels: 'Labels',
    milestones: 'Milestone',
    projects: 'Projects',
    types: 'Type',
  },
  awaitingTriage: 'Awaiting triage',
  defaultProjectFieldName: 'Status',
  moveCardToColumn: 'Move card to column',
  selectProjects: 'Select projects',
  filterProjects: 'Filter projects',
  showClosedProjects: 'Show closed projects',
  showMoreProjectFields: 'Show more project fields',
}
